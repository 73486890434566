import React, { useState, useEffect } from 'react';
import { MessageSquare, ThumbsUp, ThumbsDown } from 'lucide-react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const FeedbackItem = ({ feedback, isReceived }) => {
  const [employeeName, setEmployeeName] = useState('');

  useEffect(() => {
    const fetchEmployeeName = async () => {
      try {
        const companyResponse = await axios.get(`${API_URL}/company`, { withCredentials: true });
        const companyId = companyResponse.data.id;
        const employeeId = isReceived ? feedback.from_employee_id : feedback.to_employee_id;
        const employeeResponse = await axios.get(`${API_URL}/employees/${employeeId}`, { withCredentials: true });
        const employee = employeeResponse.data;
        setEmployeeName(`${employee.first_name} ${employee.last_name}`);
      } catch (error) {
        console.error('Failed to fetch employee name:', error);
        setEmployeeName('Unknown');
      }
    };

    fetchEmployeeName();
  }, [feedback, isReceived]);

  const getFeedbackTypeIcon = () => {
    switch (feedback.type) {
      case 'positive':
        return <ThumbsUp className="text-green-500" />;
      case 'constructive':
        return <ThumbsDown className="text-yellow-500" />;
      default:
        return <MessageSquare className="text-blue-500" />;
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-4">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          {getFeedbackTypeIcon()}
          <span className="ml-2 font-semibold">
            {isReceived ? 'Received from' : 'Sent to'}: {employeeName}
          </span>
        </div>
        <span className="text-sm text-gray-500">{new Date(feedback.date).toLocaleDateString()}</span>
      </div>
      <p className="text-gray-700">{feedback.content}</p>
      <div className="mt-2 text-sm text-gray-500">Status: {feedback.status}</div>
    </div>
  );
};

export default FeedbackItem;