import axios from 'axios';
import React, { useState, useEffect } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

const GeneratedCPF = ({ cpf, setCpf }) => {
  const [modification, setModification] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [localCpf, setLocalCpf] = useState(cpf);

  useEffect(() => {
    setLocalCpf(cpf);
  }, [cpf]);

  if (!localCpf || !localCpf.framework || !localCpf.framework.tracks) return null;

  const { framework, explanation, recommendations } = localCpf;

  const handleModification = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}/modify_career_framework`, {
        framework: localCpf.framework,
        modification: modification
      }, {
        withCredentials: true
      });
      setLocalCpf(response.data);
      setCpf(response.data);
      setModification('');
    } catch (error) {
      console.error('Error modifying CPF:', error);
    }
    setIsLoading(false);
  };

  // Add this function to generate colors for tracks
  const getTrackColor = (index) => {
    const colors = ['#FFA07A', '#98FB98', '#87CEFA', '#DDA0DD', '#F0E68C'];
    return colors[index % colors.length];
  };

  return (
    <div className="w-full max-w-7xl">
      <h2 className="text-2xl font-bold mb-4">{framework.name}</h2>

      <div className="mt-8 mb-4">

        {explanation && (<>
        <h3 className="text-xl font-bold mb-2">Explanation</h3>
        <p className="mb-4">{explanation}</p>
        </>)}

        {recommendations && (<>
        <h3 className="text-xl font-bold mb-2">Recommendations</h3>
        <ul className="list-disc pl-5 mb-8">
          {recommendations.map((rec, index) => (
            <li key={index} className="mb-1">{rec}</li>
          ))}
        </ul>
        </>)}

      </div>

      {framework.tracks.map((track, trackIndex) => {
        const trackColor = getTrackColor(trackIndex);
        return (
          <div key={trackIndex} className="mb-8">
            <h3 className="text-xl font-bold mb-2">{track.name}</h3>
            <p className="mb-4">{track.description}</p>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-300">
                <thead>
                  <tr style={{ backgroundColor: trackColor }}>
                    {track.levels.map((level, levelIndex) => (
                      <th key={levelIndex} className="px-4 py-2 text-left font-semibold border-b">{level.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {track.levels.map((level, levelIndex) => (
                      <td key={levelIndex} className="px-4 py-2 border-b align-top" style={{ borderColor: trackColor }}>
                        <h4 className="font-semibold mb-2">Responsibilities:</h4>
                        <ul className="list-disc pl-5 mb-4">
                          {level.responsibilities.map((resp, i) => (
                            <li key={i} className="mb-1">{resp}</li>
                          ))}
                        </ul>
                        <h4 className="font-semibold mb-2">Skills & Competencies:</h4>
                        <ul className="list-disc pl-5 mb-4">
                          {level.skills_competencies.map((skill, i) => (
                            <li key={i} className="mb-1">{skill}</li>
                          ))}
                        </ul>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      })}

      <div className="mt-8">
        <h3 className="text-xl font-bold mb-2">Modify CPF</h3>
        <textarea
          className="w-full p-2 border rounded"
          rows="4"
          value={modification}
          onChange={(e) => setModification(e.target.value)}
          placeholder="Describe the modifications you want to make to the CPF..."
        ></textarea>
        <button
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
          onClick={handleModification}
          disabled={isLoading || !modification.trim()}
        >
          {isLoading ? 'Modifying...' : 'Modify CPF'}
        </button>
      </div>
    </div>
  );
};

export default GeneratedCPF;