import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import Layout from './components/Layout';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import Create from './pages/Create';
import Settings from './pages/Settings';
import { setupAxiosInterceptors } from './utils/auth';
import JobOffers from './pages/JobOffers';
import CreateJobOffer from './pages/CreateJobOffer';
import JobOfferDetails from './pages/JobOfferDetails';
import Company from './pages/Company';
import Employees from './pages/Employees';
import CreateEmployee from './pages/CreateEmployee'; // Add this line
import EmployeeDetails from './pages/EmployeeDetails';

const isAuthenticated = () => {
  return localStorage.getItem('isAuthenticated') === 'true';
};

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  useEffect(() => {
    setupAxiosInterceptors();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Create />} />
          <Route path="settings" element={<Settings />} />
          <Route path="job-offers" element={<JobOffers />} />
          <Route path="job-offers/:id" element={<JobOfferDetails />} />
          <Route path="create-job-offer" element={<CreateJobOffer />} />
          <Route path="company" element={<Company />} />
          <Route path="employees" element={<Employees />} />
          <Route path="employees/:id" element={<EmployeeDetails />} />
          <Route path="create-employee" element={<CreateEmployee />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Analytics />
    </BrowserRouter>
  );
}

export default App;
