import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Loader2, AlertCircle } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const CPFCreator = () => {
  const [formData, setFormData] = useState({
    tracks_to_create: '',
    number_of_levels_per_track: '',
    company_name: '',
    industry: '',
    number_of_employees: '',
  });
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  const fetchCompanyInfo = async () => {
    try {
      const response = await axios.get(`${API_URL}/company`, {
        withCredentials: true
      });
      setCompany(response.data);
    } catch (err) {
      if (err.response?.status !== 404) {
        setError(err.response?.data?.detail || 'An error occurred while fetching company information.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const createCompany = async () => {
    try {
      const response = await axios.post(`${API_URL}/company`, {
        name: formData.company_name,
        industry: formData.industry,
        number_of_employees: parseInt(formData.number_of_employees),
      }, {
        withCredentials: true
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.detail || 'An error occurred while creating the company.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      let companyData = company;
      if (!companyData) {
        companyData = await createCompany();
      }

      const requestData = {
        name: `${companyData.name}'s Framework`,
        company: {
          name: companyData.name,
          industry: companyData.industry,
          number_of_employees: companyData.number_of_employees,
        },
        tracks_to_create: formData.tracks_to_create.split(',').map(item => item.trim()),
        number_of_levels_per_track: formData.number_of_levels_per_track,
      };

      await axios.post(`${API_URL}/create_career_framework`, requestData, {
        withCredentials: true
      });

      // Refresh the page after successful CPF creation
      window.location.reload();

    } catch (err) {
      setError(err.message || 'An error occurred while generating the CPF.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="animate-spin mr-2" />
        <span>Loading...</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <h1 className="text-4xl text-gray-800 mb-10">Generate a new Career Development Framework</h1>
      <form onSubmit={handleSubmit} className="w-full max-w-lg mb-8">
        <h2 className="text-xl font-bold mb-4 mt-8">Company Information</h2>
        {company ? (
          <div className="mb-4">
            <p><strong>Company Name:</strong> {company.name}</p>
            <p><strong>Industry:</strong> {company.industry}</p>
            <p><strong>Number of Employees:</strong> {company.number_of_employees}</p>
          </div>
        ) : (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
                Company Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="company_name"
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="industry">
                Industry
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="industry"
                type="text"
                name="industry"
                value={formData.industry}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number_of_employees">
                Number of Employees
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="number_of_employees"
                type="number"
                name="number_of_employees"
                value={formData.number_of_employees}
                onChange={handleInputChange}
                required
              />
            </div>
          </>
        )}
        <h2 className="text-xl font-bold mb-4 mt-8">Framework Structure</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tracks_to_create">
            Career Tracks (comma-separated)
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="tracks_to_create"
            type="text"
            name="tracks_to_create"
            value={formData.tracks_to_create}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number_of_levels_per_track">
            Number of Levels per Track (e.g. "from 3 to 5")
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="number_of_levels_per_track"
            type="text"
            name="number_of_levels_per_track"
            value={formData.number_of_levels_per_track}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <>
                <Loader2 className="inline-block mr-2 animate-spin" />
                Generating...
              </>
            ) : (
              'Generate CPF'
            )}
          </button>
        </div>
      </form>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <AlertCircle className="inline-block mr-2" />
          <span className="block sm:inline">{error}</span>
        </div>
      )}
    </div>
  );
};

export default CPFCreator;
