import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const CreateJobOffer = () => {
  const [trackLevels, setTrackLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    track_level_id: '',
    salary_range: '',
    interview_process: '',
    company_culture: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchTrackLevels();
  }, []);

  const fetchTrackLevels = async () => {
    try {
      const response = await axios.get(`${API_URL}/track_levels`, { withCredentials: true });
      setTrackLevels(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch track levels');
      setLoading(false);
    }
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'track_level_id') {
      try {
        const response = await axios.get(`${API_URL}/track_levels/${value}`, { withCredentials: true });
        const { salary_range_min, salary_range_max } = response.data;
        const suggestedRange = `$${salary_range_min.toLocaleString()} - $${salary_range_max.toLocaleString()} per year`;
        const title = response.data.name;
        setFormData(prevData => ({ ...prevData, salary_range: suggestedRange, title: title }));
      } catch (err) {
        console.error('Failed to fetch suggested salary range:', err);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${API_URL}/job_offers`, formData, { withCredentials: true });
      navigate('/job-offers');
    } catch (err) {
      setError('Failed to create job offer');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="animate-spin mr-2" />
        <span>Loading...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Create New Job Offer</h1>
      <form onSubmit={handleSubmit} className="max-w-lg">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="track_level_id">
            Track Level
          </label>
          <select
            id="track_level_id"
            name="track_level_id"
            value={formData.track_level_id}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select a track level</option>
            {trackLevels.map((level) => (
              <option key={level.id} value={level.id}>
                {level.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="salary_range">
            Title
          </label>
          <input
            id="title"
            name="title"
            type="text"
            value={formData.title} // based on the track level
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="salary_range">
            Salary Range
          </label>
          <input
            id="salary_range"
            name="salary_range"
            type="text"
            value={formData.salary_range}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="interview_process">
            Interview Process
          </label>
          <textarea
            id="interview_process"
            name="interview_process"
            value={formData.interview_process}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="3"
          ></textarea>
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_culture">
            Company Culture
          </label>
          <textarea
            id="company_culture"
            name="company_culture"
            value={formData.company_culture}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="3"
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          disabled={loading}
        >
          {loading ? 'Creating...' : 'Create Job Offer'}
        </button>
      </form>
    </div>
  );
};

export default CreateJobOffer;