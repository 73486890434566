import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Loader2, MapPin, Briefcase, Calendar, ArrowLeft, Mail, DollarSign, FileText, Plus, User, Users } from 'lucide-react';
import FeedbackItem from '../components/FeedbackItem';
import CreateAssessmentModal from '../components/CreateAssessmentModal';

const API_URL = process.env.REACT_APP_API_URL;

const EmployeeDetails = () => {
  const { id } = useParams();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [manager, setManager] = useState(null);
  const [jobTitles, setJobTitles] = useState([]);
  const [receivedFeedback, setReceivedFeedback] = useState([]);
  const [sentFeedback, setSentFeedback] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [directReports, setDirectReports] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Reset states when the component mounts or when the id changes
    setEmployee(null);
    setManager(null);
    setDirectReports([]);
    setReceivedFeedback([]);
    setSentFeedback([]);
    setAssessments([]);
    setLoading(true);
    setError(null);

    const fetchEmployeeDetails = async () => {
      try {
        const companyResponse = await axios.get(`${API_URL}/company`, { withCredentials: true });
        const companyId = companyResponse.data.id;

        const employeeResponse = await axios.get(`${API_URL}/employees/${id}`, { withCredentials: true });
        setEmployee(employeeResponse.data);

        if (employeeResponse.data.manager_id) {
          const managerResponse = await axios.get(`${API_URL}/employees/${employeeResponse.data.manager_id}`, { withCredentials: true });
          setManager(managerResponse.data);
          console.log(managerResponse.data);
        }

        if (employeeResponse.data.subordinates) {
            const subordinates = [];
            for (const subordinateId of employeeResponse.data.subordinates) {
                const subordinateResponse = await axios.get(`${API_URL}/employees/${subordinateId}`, { withCredentials: true });
                subordinates.push(subordinateResponse.data);
            }
            setDirectReports(subordinates);
        }

        const jobTitlesResponse = await axios.get(`${API_URL}/track_levels`, { withCredentials: true });
        setJobTitles(jobTitlesResponse.data);

        // Fetch received feedback
        const receivedFeedbackResponse = await axios.get(`${API_URL}/employee/${id}/feedback?as_receiver=true`, { withCredentials: true });
        setReceivedFeedback(receivedFeedbackResponse.data);

        // Fetch sent feedback
        const sentFeedbackResponse = await axios.get(`${API_URL}/employee/${id}/feedback?as_receiver=false`, { withCredentials: true });
        setSentFeedback(sentFeedbackResponse.data);

        // Fetch assessments
        const assessmentsResponse = await axios.get(`${API_URL}/employees/${id}/assessments`, { withCredentials: true });
        setAssessments(assessmentsResponse.data);

        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch employee details:', err);
        setError('Failed to fetch employee details');
        setLoading(false);
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  const handleCreateAssessment = async (newAssessment) => {
    try {
      const response = await axios.post(`${API_URL}/assessments`, newAssessment, { withCredentials: true });
      setAssessments([...assessments, response.data]);
      setIsCreateModalOpen(false);
    } catch (error) {
      console.error('Failed to create assessment:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleManagerClick = (managerId) => {
    navigate(`/employees/${managerId}`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="animate-spin mr-2" />
        <span>Loading employee details...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Link to="/employees" className="flex items-center text-blue-500 hover:underline mb-6">
        <ArrowLeft className="mr-2" />
        Back to Employees
      </Link>
      {employee && (
        <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
          <h1 className="text-3xl font-bold mb-4">{`${employee.first_name} ${employee.last_name}`}</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center">
              <MapPin className="mr-2 text-blue-500" />
              <span>{employee.work_location}</span>
            </div>
            <div className="flex items-center">
              <Calendar className="mr-2 text-red-500" />
              <span>Hired: {new Date(employee.hire_date).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center">
              <Briefcase className="mr-2 text-green-500" />
              <span>{jobTitles.find(title => title.id === employee.track_level_id)?.name}</span>
            </div>
            <div className="flex items-center">
              <Calendar className="mr-2 text-orange-500" />
              <span>Since: {new Date(employee.position_start_date).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center">
              <Mail className="mr-2 text-purple-500" />
              <span>{employee.email}</span>
            </div>
            <div className="flex items-center">
              <DollarSign className="mr-2 text-yellow-500" />
              <span>{employee.salary}</span>
            </div>
            {manager && (
              <div className="flex items-center col-span-2">
                <User className="mr-2 text-indigo-500" />
                <span>Manager: {manager.first_name} {manager.last_name}</span>
                <button
                  onClick={() => handleManagerClick(manager.id)}
                  className="ml-2 text-blue-500 hover:underline"
                >
                  View Profile
                </button>
              </div>
            )}
          </div>

          {directReports.length > 0 && (
            <div className="mt-6">
              <h2 className="text-xl font-bold mb-3 flex items-center">
                <Users className="mr-2 text-blue-500" />
                Direct Reports
              </h2>
              <ul className="space-y-2">
                {directReports.map(report => (
                  <li key={report.id} className="flex items-center">
                    <User className="mr-2 text-gray-500" />
                    <Link to={`/employees/${report.id}`} className="text-blue-500 hover:underline">
                      {report.first_name} {report.last_name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div>
          <h2 className="text-2xl font-bold mb-4">Received Feedback</h2>
          {receivedFeedback.length > 0 ? (
            receivedFeedback.map(feedback => (
              <FeedbackItem key={feedback.id} feedback={feedback} isReceived={true} />
            ))
          ) : (
            <p>No feedback received yet.</p>
          )}
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4">Sent Feedback</h2>
          {sentFeedback.length > 0 ? (
            sentFeedback.map(feedback => (
              <FeedbackItem key={feedback.id} feedback={feedback} isReceived={false} />
            ))
          ) : (
            <p>No feedback sent yet.</p>
          )}
        </div>
      </div>

      <div>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Assessments</h2>
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center"
          >
            <Plus className="mr-2" /> Create Assessment
          </button>
        </div>
        {assessments.length > 0 ? (
          <div className="space-y-4">
            {assessments.map(assessment => (
              <div key={assessment.id} className="bg-white shadow-md rounded-lg p-4">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-lg font-semibold flex items-center">
                    <FileText className="mr-2 text-blue-500" />
                    {assessment.type === 'self_assessment' ? 'Self Assessment' : 'Performance Review'}
                  </h3>
                  <span className="text-sm text-gray-500">{new Date(assessment.date).toLocaleDateString()}</span>
                </div>
                <div className="text-sm text-gray-600 mb-2">
                  Status: <span className={`font-semibold ${assessment.status === 'Completed' ? 'text-green-500' : 'text-yellow-500'}`}>{assessment.status}</span>
                </div>
                <div className="space-y-2">
                  {assessment.content.map((qa, index) => (
                    <div key={index} className="border-t pt-2">
                      <p className="font-medium">{qa.question}</p>
                      <p className="text-gray-700">{qa.answer}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No assessments available.</p>
        )}
      </div>

      <CreateAssessmentModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={handleCreateAssessment}
        employeeId={id}
      />
    </div>
  );
};

export default EmployeeDetails;
