import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Plus, Loader2, MapPin, Briefcase, Calendar, Search } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jobTitles, setJobTitles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterJobTitle, setFilterJobTitle] = useState('');
  const [sortOption, setSortOption] = useState('name');

  useEffect(() => {
    fetchCompanyAndEmployees();
  }, []);

  const fetchCompanyAndEmployees = async () => {
    try {
      // First, fetch the company information
      const companyResponse = await axios.get(`${API_URL}/company`, { withCredentials: true });

      // Then, fetch the employees using the company ID
      const employeesResponse = await axios.get(`${API_URL}/employees`, { withCredentials: true });
      setEmployees(employeesResponse.data);

      // Fetch the job titles
      const jobTitlesResponse = await axios.get(`${API_URL}/track_levels`, { withCredentials: true });
      setJobTitles(jobTitlesResponse.data);
      console.log(jobTitlesResponse.data);

      setLoading(false);

    } catch (err) {
      setError('Failed to fetch company or employees');
      setLoading(false);
    }
  };

  const filteredEmployees = employees.filter(employee => {
    const fullName = `${employee.first_name} ${employee.last_name}`.toLowerCase();
    const jobTitle = jobTitles.find(title => title.id === employee.track_level_id)?.name.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase()) &&
           (filterJobTitle === '' || jobTitle === filterJobTitle.toLowerCase());
  });

  const sortedAndFilteredEmployees = filteredEmployees.sort((a, b) => {
    switch (sortOption) {
      case 'name':
        return `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`);
      case 'location':
        return a.work_location.localeCompare(b.work_location);
      case 'position':
        const aTitle = jobTitles.find(title => title.id === a.track_level_id)?.name || '';
        const bTitle = jobTitles.find(title => title.id === b.track_level_id)?.name || '';
        return aTitle.localeCompare(bTitle);
      case 'hireDate':
        return new Date(a.hire_date) - new Date(b.hire_date);
      default:
        return 0;
    }
  });

  const renderEmployeeCards = () => {
    let currentGroup = '';

    return sortedAndFilteredEmployees.map((employee, index) => {
      let groupHeader = null;
      const groupValue = sortOption === 'location' ? employee.work_location :
                         sortOption === 'position' ? jobTitles.find(title => title.id === employee.track_level_id)?.name :
                         null;

      if (groupValue && groupValue !== currentGroup) {
        currentGroup = groupValue;
        groupHeader = (
          <div key={`header-${groupValue}`} className="col-span-full border-b border-gray-300 mb-6 mt-8 first:mt-0">
            <h3 className="text-lg font-semibold text-gray-700 pb-2">{groupValue}</h3>
          </div>
        );
      }

      return (
        <React.Fragment key={employee.id}>
          {groupHeader}
          <Link to={`/employees/${employee.id}`} className="block">
            <div className="bg-gray-50 shadow-md rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg border border-gray-200">
              <h2 className="text-2xl font-semibold mb-4 text-center text-gray-800">{`${employee.first_name} ${employee.last_name}`}</h2>
              <div className="space-y-2 text-center">
                <p className="text-gray-600 flex items-center justify-center">
                  <MapPin className="w-4 h-4 mr-2 text-blue-500" />
                  {employee.work_location}
                </p>
                <p className="text-gray-600 flex items-center justify-center">
                  <Briefcase className="w-4 h-4 mr-2 text-green-500" />
                  {jobTitles.find(title => title.id === employee.track_level_id)?.name}
                </p>
                <p className="text-gray-600 flex items-center justify-center">
                  <Calendar className="w-4 h-4 mr-2 text-red-500" />
                  Hired: {new Date(employee.hire_date).toLocaleDateString()}
                </p>
              </div>
            </div>
          </Link>
        </React.Fragment>
      );
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="animate-spin mr-2" />
        <span>Loading employees...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Employees</h1>
        <Link
          to="/create-employee"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <Plus className="mr-2" />
          Add New Employee
        </Link>
      </div>

      <div className="mb-6 flex flex-col md:flex-row md:items-center md:space-x-4">
        <div className="relative flex-grow mb-4 md:mb-0">
          <input
            type="text"
            placeholder="Search employees..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        <select
          value={filterJobTitle}
          onChange={(e) => setFilterJobTitle(e.target.value)}
          className="w-full md:w-auto px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">All Job Titles</option>
          {jobTitles.map(title => (
            <option key={title.id} value={title.name}>{title.name}</option>
          ))}
        </select>
        <select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          className="w-full md:w-auto px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="name">Sort by Name</option>
          <option value="location">Sort by Location</option>
          <option value="position">Sort by Position</option>
          <option value="hireDate">Sort by Hire Date</option>
        </select>
      </div>

      {sortedAndFilteredEmployees.length === 0 ? (
        <p>No employees found. Add your first employee or adjust your search criteria!</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {renderEmployeeCards()}
        </div>
      )}
    </div>
  );
};

export default Employees;