import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Loader2, AlertCircle } from 'lucide-react';
import GeneratedCPF from '../components/GeneratedCPF';
import CPFCreator from '../components/CPFCreator';
const API_URL = process.env.REACT_APP_API_URL;

const DeleteCPF = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete your Career Progression Framework? This action cannot be undone.")) {
      setLoading(true);
      setError(null);
      try {
        await axios.delete(`${API_URL}/career_framework`, { withCredentials: true });
        navigate('/');
      } catch (err) {
        setError(err.response?.data?.detail || 'An error occurred while deleting the CPF.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="mt-6">
      <h3 className="text-xl font-semibold mb-2">Delete Career Progression Framework</h3>
      <p className="mb-2">This action will permanently delete your Career Progression Framework.</p>
      <button
        onClick={handleDelete}
        disabled={loading}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        {loading ? 'Deleting...' : 'Delete CPF'}
      </button>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default function Create() {
  const [existingCPF, setExistingCPF] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    checkExistingCPF();
  }, []);

  const checkExistingCPF = async () => {
    try {
      const response = await axios.get(`${API_URL}/career_framework`, {
        withCredentials: true
      });
      setExistingCPF(response.data);
    } catch (err) {
      if (err.response?.status !== 404) {
        setError(err.response?.data?.detail || 'An error occurred while checking for existing CPF.');
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="animate-spin mr-2" />
        <span>Loading...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
        <AlertCircle className="inline-block mr-2" />
        {error}
      </div>
    );
  }

  if (existingCPF) {
    return (
      <div className="container mx-auto px-4 py-8">
        <GeneratedCPF cpf={{ framework: existingCPF }} />
        <DeleteCPF />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <CPFCreator />
    </div>
  );
}
