import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Loader2, ArrowLeft } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const JobOfferDetails = () => {
  const [jobOffer, setJobOffer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchJobOffer();
  }, [id]);

  const fetchJobOffer = async () => {
    try {
      const response = await axios.get(`${API_URL}/job_offers/${id}`, { withCredentials: true });
      setJobOffer(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch job offer details');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="animate-spin mr-2" />
        <span>Loading job offer details...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!jobOffer) {
    return <div className="text-center">No job offer found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Link to="/job-offers" className="flex items-center text-blue-500 hover:text-blue-700 mb-4">
        <ArrowLeft className="mr-2" />
        Back to Job Offers
      </Link>
      <h1 className="text-3xl font-bold mb-6">{jobOffer.title}</h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Summary</h2>
        <p className="mb-4">{jobOffer.summary}</p>

        <h2 className="text-xl font-semibold mb-4">Description</h2>
        <p className="mb-4">{jobOffer.description}</p>

        <h2 className="text-xl font-semibold mb-4">Required Qualifications</h2>
        <ul className="list-disc pl-5 mb-4">
          {jobOffer.required_qualifications.map((qual, index) => (
            <li key={index}>{qual}</li>
          ))}
        </ul>

        <h2 className="text-xl font-semibold mb-4">Preferred Qualifications</h2>
        <ul className="list-disc pl-5 mb-4">
          {jobOffer.preferred_qualifications.map((qual, index) => (
            <li key={index}>{qual}</li>
          ))}
        </ul>

        <h2 className="text-xl font-semibold mb-4">Responsibilities</h2>
        <ul className="list-disc pl-5 mb-4">
          {jobOffer.responsibilities.map((resp, index) => (
            <li key={index}>{resp}</li>
          ))}
        </ul>

        <h2 className="text-xl font-semibold mb-4">Benefits</h2>
        <ul className="list-disc pl-5 mb-4">
          {jobOffer.benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>

        <h2 className="text-xl font-semibold mb-4">Additional Information</h2>
        <p><strong>Salary Range:</strong> {jobOffer.salary_range}</p>
        <p><strong>Interview Process:</strong> {jobOffer.interview_process}</p>
        <p><strong>Company Culture:</strong> {jobOffer.company_culture}</p>
      </div>
    </div>
  );
};

export default JobOfferDetails;