import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Plus, Loader2 } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const JobOffers = () => {
  const [jobOffers, setJobOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchJobOffers();
  }, []);

  const fetchJobOffers = async () => {
    try {
      const response = await axios.get(`${API_URL}/job_offers`, { withCredentials: true });
      setJobOffers(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch job offers');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="animate-spin mr-2" />
        <span>Loading job offers...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Job Offers</h1>
        <Link
          to="/create-job-offer"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <Plus className="mr-2" />
          Create New Job Offer
        </Link>
      </div>
      {jobOffers.length === 0 ? (
        <p>No job offers found. Create your first job offer!</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {jobOffers.map((offer) => (
            <Link
              key={offer.id}
              to={`/job-offers/${offer.id}`}
              className="bg-white shadow-md rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
            >
              <h2 className="text-xl font-semibold mb-2">{offer.title}</h2>
              <p className="text-gray-600 mb-4">{offer.summary}</p>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default JobOffers;