import React, { useState, useEffect } from 'react';
import { X, Plus, Minus, Loader2 } from 'lucide-react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const CreateAssessmentModal = ({ isOpen, onClose, onSubmit, employeeId }) => {
  const [type, setType] = useState('self_assessment');
  const [questions, setQuestions] = useState(['']);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setQuestions(['']);
      setType('self_assessment');
    }
  }, [isOpen]);

  const handleAddQuestion = () => {
    setQuestions([...questions, '']);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index] = value;
    setQuestions(newQuestions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newAssessment = {
      employee_id: employeeId,
      type: type,
      content: questions.filter(q => q.trim() !== '').map(q => ({ question: q, answer: '' })),
    };
    onSubmit(newAssessment);
  };

  const handleGenerateSuggestedQuestions = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/employees/${employeeId}/suggested-assessment-questions`, {
        params: { assessment_type: type },
        withCredentials: true
      });
      setQuestions(response.data.questions);
    } catch (error) {
      console.error('Failed to fetch suggested questions:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-y-auto">
      <div className="bg-white rounded-lg w-full max-w-4xl m-4 pl-8 pr-4 py-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Create New Assessment</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={20} />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2 font-semibold">Assessment Type</label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="w-full p-2 border rounded-lg"
            >
              <option value="self_assessment">Self Assessment</option>
              <option value="performance_review">Performance Review</option>
            </select>
          </div>
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <label className="font-semibold">Questions</label>
              <button
                type="button"
                onClick={handleGenerateSuggestedQuestions}
                className="bg-green-500 text-white px-3 py-1 rounded-lg text-sm font-semibold hover:bg-green-600 flex items-center"
                disabled={isLoading}
              >
                {isLoading ? <Loader2 className="animate-spin mr-2" size={16} /> : null}
                Generate Suggested Questions
              </button>
            </div>
            {questions.map((question, index) => (
              <div key={index} className="flex items-center mb-3">
                <textarea
                  value={question}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                  placeholder={`Question ${index + 1}`}
                  className="w-full p-2 border rounded-lg resize-vertical min-h-[80px]"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveQuestion(index)}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                  <Minus size={20} />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddQuestion}
              className="flex items-center text-blue-500 hover:text-blue-700"
            >
              <Plus size={20} className="mr-1" /> Add Question
            </button>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-600"
            >
              Create Assessment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAssessmentModal;