import React from 'react';
import CompanyProfile from '../components/CompanyProfile';

export default function Company() {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Company</h1>
      <CompanyProfile />
    </div>
  );
}